<template>
	<QuickfoldersContainer v-if="company && company.id" />
</template>

<script>
import { mapState } from 'vuex'
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'EurexQuickfolders',
	components: {
		QuickfoldersContainer: () => ({
			component: import('@/components/Eurex/Quickfolders/QuickfoldersContainer')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	computed: {
		...mapState({
			companies: state => state.company.list,
			company: state => state.company.selected
		})
	},
	watch: {
		companies: {
			handler: function (val) {
				if (!val || val.length === 0) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('quickfolders.no_company'))
				}
			},
			immediate: true
		}
	}
}
</script>
